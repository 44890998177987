export const tHeaderList = Object.freeze([
  {
    label: '下单人',
    prop: 'operatorAccount',
    type: '',
    width: '120px'
  },
  { label: '交易时间', prop: 'createTime', type: 'date', width: '' },
  { label: '方向', prop: 'entrustBs', type: 'entrustBs', width: '' },
  {
    label: '产品',
    prop: 'stock',
    type: 'twoLinesNewsLines',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  { label: '价格', prop: 'entrustPrice', type: 'price', width: '' },
  { label: '总数量', prop: 'entrustAmount', type: 'permillage', width: '' },
  { label: '下单账户数', prop: 'entrustFundcount', type: '', width: '' },
  {
    label: '状态',
    prop: 'stateText',
    type: ''
    // btnType: 'status'
  },
  {
    label: '操作',
    prop: 'action',
    type: 'setting',
    crossFlag: true,
    btnType: 'detail',
    setting: [
      {
        name: '查看详情',
        btnType: 'detail',
        color: 'text',
        type: 'show',
        permission: []
      }
    ],
    btns: [
      {
        name: '一键撤单',
        btnType: 'cancel',
        color: 'text',
        typeReal: 'state', // 显示控制的字段
        showValue: [0] // 具体字段显示的值
      },
      {
        name: '一键改单',
        btnType: 'update',
        color: 'text',
        typeReal: 'state', // 显示控制的字段
        showValue: [0] // 具体字段显示的值
      }
    ],
    width: ''
  }
])
