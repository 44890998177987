
import { computed, defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { STATES } from '@/constant/index'
import { useMutation } from '@vue/apollo-composable'
import { lpoaCancelOrderOneKey } from '@/graphql'
import { numFormat } from '@/utils/num_format'

export default defineComponent({
  name: 'Revoke',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Object,
      default: () => ({})
    }
  },
  inheritAttrs: false,
  emits: [
    'update:dialogVisible',
    'refreshOrder',
    // 'changeLoading',
    'changeConLoading'
  ],
  setup (props, { emit }) {
    const handleClose = () => {
      emit('update:dialogVisible', false)
    }
    const orderid = computed(() => props.list.id)
    const { mutate: oneKeyCancel, onDone, loading } = useMutation(
      lpoaCancelOrderOneKey
    )

    const cancelOrder = () => {
      oneKeyCancel({
        params: {
          orderid: orderid.value
        }
      })
    }
    onDone(res => {
      const { lpoaCancelOrderOneKey } = res.data
      if (lpoaCancelOrderOneKey.ok) {
        ElMessage({
          message: '撤单已提交',
          type: 'success',
          duration: 1500,
          customClass: 'custom-success'
        })
        emit('update:dialogVisible', false)
        emit('refreshOrder')
      }
    })
    const contentLoading = ref(true)
    if (props.list) {
      contentLoading.value = false
    }
    return {
      STATES,
      handleClose,
      cancelOrder,
      numFormat,
      contentLoading,
      loading
    }
  }
})
