
import { defineComponent, reactive, ref, toRefs } from 'vue'
import useOrders from './hooks/useOrders'
import { tHeaderList } from './hooks/data'
import { useRouter } from 'vue-router'
import * as crypoto from '@/utils/crypto'
import _ from 'lodash'
import dayjs from 'dayjs'
import Revoke from './components/Revoke.vue'
import useOrder from '@/views/LPOA/realTimeData/hooks/useOrder'
import useAllOrders from './hooks/useAllOrders'
import * as gql from '@/graphql'
import useExportTable from '@/hooks/useExportTable'

export default defineComponent({
  name: 'SplitOrder',
  components: {
    Revoke
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      dialogVisible: false,
      timeOptions: [
        {
          name: '近一个月',
          value: 0
        },
        {
          name: '近半年',
          value: 1
        },
        {
          name: '近一年',
          value: 2
        },
        {
          name: '自定义',
          value: 3
        }
      ],
      activeIndex: 0,
      date: '',
      params: {
        startDate: dayjs().subtract(1, 'month').format('YYYYMMDD'),
        endDate: dayjs().format('YYYYMMDD'),
        pageNo: 1,
        pageSize: 10
      }
    })
    const filterDate = (key: number) => {
      switch (key) {
        case 0:
          state.params.startDate = dayjs()
            .subtract(2, 'month')
            .format('YYYYMMDD')
          break
        case 1:
          state.params.startDate = dayjs()
            .subtract(6, 'month')
            .format('YYYYMMDD')
          break
        case 2:
          state.params.startDate = dayjs()
            .subtract(1, 'year')
            .format('YYYYMMDD')
          break
        default:
          break
      }
    }
    const selectTime = (val: number) => {
      state.activeIndex = val
      filterDate(val)
    }
    const changeDate = (val: any) => {
      state.params.startDate = dayjs(val[0]).format('YYYYMMDD')
      state.params.endDate = dayjs(val[1]).format('YYYYMMDD')
    }
    const handleSizeChange = (val: number) => {
      console.log(`${val} items per page`)
    }
    const handleCurrentChange = (val: number) => {
      console.log(`current page: ${val}`)
    }
    const { total, loading, refetch, orderList, accList } = useOrders(
      state.params
    )
    const { load, orderInfotableData } = useOrder(accList)
    const change = (data: any) => {
      const subOrders = data.data.subOrders
      const {
        exchangeType,
        stockCode,
        stockName: stockNameGb,
        entrustBs,
        entrustProp,
        extendedTrading
      } = data.data
      const arr = subOrders.map((item: any) => {
        return {
          ...item,
          exchangeType,
          stockCode,
          stockNameGb
        }
      })
      const miniPrice = _.min(
        subOrders.map((item: any) => parseFloat(item.entrustPrice))
      )
      const type = {
        entrustBs,
        entrustProp,
        entrustPrice: miniPrice,
        extendedTrading
      }
      switch (data.btnType) {
        case 'cancel':
          state.dialogVisible = true
          // 查询订单详情
          const params = {
            filter: {
              directiveid: data.data.id
            }
          }
          load(gql.lpoaOrderInfo, params)
          break
        // 查看详情
        case 'detail':
          router.push({
            name: 'CheckDetail',
            query: {
              orderid: data?.data?.id
            }
          })
          break
        // 一键改单
        case 'update':
          router.push({
            name: 'BulkTrading',
            query: {
              bidAskList: crypoto.base64Encrypt(JSON.stringify(arr)),
              type: JSON.stringify(type),
              orderid: data?.data?.id
            }
          })
          break
        default:
          break
      }
    }
    const params = ref({
      pageNo: 1,
      startDate: '',
      endDate: ''
    })
    const { allOrders, loadAll, exportLoading } = useAllOrders(params.value)
    const exportTable = () => {
      params.value.startDate = state.params.startDate
      params.value.endDate = state.params.endDate
      loadAll()
      setTimeout(() => {
        if (!exportLoading.value) {
          console.log('导出', allOrders.value)
          useExportTable(tHeaderList, allOrders.value, '分仓交易指令')
        }
      }, 1500)
    }

    return {
      exportTable,
      orderInfotableData,
      refetch,
      selectTime,
      ...toRefs(state),
      orderList,
      change,
      loading,
      tHeaderList,
      changeDate,
      total,
      handleCurrentChange,
      handleSizeChange,
      exportLoading
    }
  }
})
