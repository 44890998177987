import { useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import { useColor } from '../../realTimeData/hooks/useColor'
import { LPOASTATE } from '@/constant/index'

type ParamsInpt = {
  startDate: string
  endDate: string
  pageNo: number
  pageSize: number
}

export default function useOrders (params: ParamsInpt) {
  const orderList = ref()
  const accList = ref()
  const total = ref()

  const { onResult, loading, refetch } = useQuery(gql.queryOrders, {
    filter: params
  })

  onResult(res => {
    const { lpoaOrderList, lpoaManagersBindInfoList }: any = res.data
    accList.value = lpoaManagersBindInfoList.data
    orderList.value = lpoaOrderList.rows?.map((item: any) => {
      return {
        ...item,
        stateText: LPOASTATE[item.state]
      }
    })
    total.value = lpoaOrderList.totalCount
    useColor(lpoaOrderList.rows)
  })
  return {
    loading,
    refetch,
    orderList,
    accList,
    total
  }
}
