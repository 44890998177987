import { useLazyQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import { ENTRUSTBSMAP, LPOASTATE } from '@/constant'
import dayjs from 'dayjs'

type ParamsInpt = {
  startDate: string
  endDate: string
  pageNo: number
  pageSize?: number
}

export default function useAllOrders (params: ParamsInpt) {
  const allOrders = ref()

  const { loading, onResult, load } = useLazyQuery(gql.queryOrders, {
    filter: params
  })
  onResult(res => {
    const { lpoaOrderList } = res?.data
    allOrders.value = lpoaOrderList.rows.map(
      (item: { [x: string]: string }) => {
        item.entrustBs = ENTRUSTBSMAP[item.entrustBs]
        item.stock = `${item.stockName} ${item.stockCode}.${item.exchangeType}`
        item.state = LPOASTATE[item.state]
        item.createTime = dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss')
        item.entrustAmount = Number(item.entrustAmount).toLocaleString()
        return item
      }
    )
    console.log(allOrders.value, 'shiji allOrders')
  })
  return {
    loadAll: load,
    allOrders,
    exportLoading: loading
  }
}
